import React, { useState, useEffect } from "react";

import ssbclogo from './assets/ssbc-logo-all-white.png';
import './App.css';

function Calculator() {
    const [data, setData] = useState({
        "Professional Budget": {"Last Week Service Sales": "", "Budget %": "", "Your Order Budget:": ""},
        "Retail Budget": {"Last Week Retail Sales": "", "Budget %": "", "Your Order Budget:": ""}
  
    });

useEffect(() => {
    if(data["Professional Budget"]["Last Week Service Sales"] !== "" && data["Professional Budget"]["Budget %"] !== ""){
        const lastWeeksProfessionalSales = data["Professional Budget"]["Last Week Service Sales"];
        const budgetPercent = data["Professional Budget"]["Budget %"];
        const budgetDollars = isNaN(lastWeeksProfessionalSales * (budgetPercent / 100)) ? '' : lastWeeksProfessionalSales * (budgetPercent / 100);
        setData((data) => ({
            ...data,
            "Professional Budget": {
                ...data["Professional Budget"],
                "Your Order Budget:": budgetDollars,
            },
        }));
    }
    }, [data["Professional Budget"]["Last Week Service Sales"]]);

    useEffect(() => {
        if(data["Retail Budget"]["Last Week Retail Sales"] !== "" && data["Retail Budget"]["Budget %"] !== ""){
            const lastWeeksRetailSales = data["Retail Budget"]["Last Week Retail Sales"];
            const budgetPercent = data["Retail Budget"]["Budget %"];
            const budgetDollars = isNaN(lastWeeksRetailSales * (budgetPercent / 100)) ? '' : lastWeeksRetailSales * (budgetPercent / 100);
            setData((data) => ({
                ...data,
                "Retail Budget": {
                    ...data["Retail Budget"],
                    "Your Order Budget:": budgetDollars,
                },
            }));
        }
    }, [data["Retail Budget"]["Last Week Retail Sales"]]);

    useEffect(() => {
        if(data["Professional Budget"]["Budget %"] !== ""){
            const budgetPercent = data["Professional Budget"]["Budget %"];
            const budgetDollars = isNaN(data["Professional Budget"]["Last Week Service Sales"] * (budgetPercent / 100)) ? '' : data["Professional Budget"]["Last Week Service Sales"] * (budgetPercent / 100);
            setData((data) => ({
                ...data,
                "Professional Budget": {
                    ...data["Professional Budget"],
                    "Your Order Budget:": budgetDollars,
                },
            }));
        }
    }, [data["Professional Budget"]["Budget %"]]);

    useEffect(() => {
        if(data["Retail Budget"]["Budget %"] !== ""){
            const budgetPercent = data["Retail Budget"]["Budget %"];
            const budgetDollars = isNaN(data["Retail Budget"]["Last Week Retail Sales"] * (budgetPercent / 100)) ? '' : data["Retail Budget"]["Last Week Retail Sales"] * (budgetPercent / 100);
            setData((data) => ({
                ...data,
                "Retail Budget": {
                    ...data["Retail Budget"],
                    "Your Order Budget:": budgetDollars,
                },
            }));
        }
    }, [data["Retail Budget"]["Budget %"]]);

const handleInputChange = (category, key, value) => {
    let numericValue;

    
    if (key === "Budget %") {
        if (value === '' || isNaN(value)) {
            value = '';
        }
        // Remove '%' and parse. If empty or non-numeric, default to 0
        const strippedValue = value.replace('%', '');
        numericValue = strippedValue ? parseFloat(strippedValue) : '';
    } else {
        // Remove currency symbols and parse. If empty or non-numeric, default to 0
        const strippedValue = value.replace(/[^0-9.-]+/g, '');
        numericValue = strippedValue ? parseFloat(strippedValue) : '';
    }

    setData((data) => ({
        ...data,
        [category]: {
            ...data[category],
            [key]: numericValue,
        },
    }));
};

    return (
        <div className="outer">
            <div style={{ display: "flex", justifyContent: "center", backgroundColor:"#55959f" }}>
                        <img src={ssbclogo} alt="Summit Salon Business Centre Company Logo" style={{ maxWidth: "45%", padding: "10px 0px" }} />
                    </div>
            <div className="title" style={{backgroundColor: "#35646b"}}>Order Budget Calculator</div>
            <div style={{backgroundColor:"#D3D3D330", display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>

    {Object.entries(data).map(([category, values]) => (
        <><div key={category} className="column-row" style={{display: "flex", flexDirection: "column", justifyContent:"center", paddingBottom:"10px"}}>
            <div style={{display:"flex", justifyContent:"flex-start", width:"100%", margin:"12px 0px"}}>
            <h1 className="category-top" style={{display:"flex", justifyContent:"flex-start", width:"100%", fontSize:"1.5em", fontStyle:"italic"}}>{category}</h1>
            </div>
            {Object.entries(values).map(([key, value]) => (
            <div style={{width: "100%", display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                <div style={{width: "45%"}}>
                <h1 className="category-top" style={{display:"flex", justifyContent:"flex-start"}}>{key}</h1>
                {key === "Budget %" ? <p style={{display:"flex", justifyContent:"flex-start", fontSize:".75em", padding:"0px", margin:"0px", color:"#ea772a"}}>Summit Guideline: Max {category == "Professional Budget" ? "10% of prior week service sales" : "52% of prior week product sales"}</p> : null}
                </div>
                <div style={{width:"45%", display:"flex", flexDirection:"row"}}>
                {key === "Your Order Budget:" ? <h1 style={{marginLeft:"10px"}}>{Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',minimumFractionDigits: 0, maximumFractionDigits: 0}).format(value)}</h1> : 
                <><input
                    style={{padding: "10px", borderRadius:"20px", fontSize: "1.2em", width:"85%", textAlign:"right"}}
                    type="text"
                    className="currentInput" // Add the invalid class if the field is invalid
                    value={
                        (key === "Last Week Service Sales" || key === "Last Week Retail Sales") ?
                          (value === '' ? '' : Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(value)) 
                          : value
                      }
                    key={key}
                    onChange={e => handleInputChange(category, key, e.target.value)}
                />
                {key === "Budget %" &&
                <p>%</p>}</>}
                </div>
            </div>
            ))}
        </div>
        {category === "Professional Budget" && <div style={{backgroundColor:"#D3D3D3", height:"2px", width:"95%"}}></div>}</>
    ))}
    </div>
        </div>
    );
}

export default Calculator;